import ApiService from "./ApiService"

export async function getCredential (data) {
    return ApiService.fetchData({
        url: '/shiprocket/getCredential',
        method: 'get',
    })
}

export async function updateCredential (data) {
    return ApiService.fetchData({
        url: '/shiprocket/updateCredential',
        method: 'patch',
        data
    })
}